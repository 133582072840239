import * as React from "react"
import Layout from "../templates/layout-standard"
import Seo from "../components/seo"
import LearnMore from '../components/learnmore';
import SideBar from '../components/sidebar';

const FindADoctor = ({ location }) => (
	<Layout location={location} heading1="Find a Doctor" heading2="Get care, when and where you need it.">
    	<Seo title="Find a Doctor" meta={[{description: 'Through MVP Health Care\'s comprehensive Medicare provider network, you have access to an extensive regional network of doctors, hospitals, and other health care professionals.'}]} bodyclass="find-a-doctor subpage" />
		
		<div className="constrained">
			<div className="content-constrained">
				<div className="flexwrap_subpage">
					<main>
						<p>No matter where you are or where you go, UVM Health Advantage plans give you the freedom and ﬂexibility to get the expert care you need, without delay or disruption.</p>
						<h3>54,000+ Doctors and Facilities</h3>
						<p>Through MVP Health Care&rsquo;s comprehensive Medicare provider network, you have access to an extensive regional network of doctors, hospitals, and other health care professionals, including The University of Vermont Health Network.</p>
						<div className="providersearch_link">
							<p><b>Search the MVP Provider network</b></p>
							<p><a href="https://mvp.healthsparq.com/healthsparq/public/#/one/insurerCode=MVP_I&brandCode=MVP" className="external_link external_button">Search Now</a></p>
							<p><a href="https://www.mvphealthcare.com/wp-content/uploads/download-manager-files/mvp-health-care-directory-of-medicare-health-care-professionals-vermont.pdf" class="download">See if your doctor is part of the UVM Health Advantage network.</a></p>
						</div>

						<h4>Virtual Care Services</h4>
						<p>Get expert care anywhere for a $0 co-pay with Gia<sup>&reg;</sup> by MVP, your 24/7 health care connection. Gia virtual care services include emergency, urgent and everyday health care, as well as mental health, psychiatry and more. All from your smartphone, phone, tablet or computer.</p>
						<p>You may also access virtual care, when available, from your local provider. But you may have to pay your usual oﬃce visit co-pay.</p>

						<h4>Worldwide Emergency and Urgent Care</h4>
						<p>You are covered anywhere for emergency and urgent care &ndash; from the hospitals and centers in your community to anyplace around the world.</p>

						<h4>Out-of-Network Coverage</h4>
						<p>UVM Health Advantage plans include coverage for non-emergency or routine care from any Medicare-participating provider anywhere in the U.S. &ndash; even if they&rsquo;re not part of our provider network. Out-of-network care and services may cost you more than in-network.</p>

						<h4>See Any Dentist</h4>
						<p>With UVM Health Advantage, you can see any dentist you choose! Your plan includes access to the nationwide DenteMax Medicare network, where your costs will be lower. You can choose an out-of-network dentist, but you may have to pay more for services.</p>
						<p><a href="https://find.dentemaxportal.com/Members/FindADentist.aspx" className="external">See if your dentist is part of the DenteMax Medicare network</a></p>

						<h4>Looking for a Pharmacy?</h4>
						<p>With UVM Health Advantage, you have access to thousands of participating pharmacies, including UVMMC Specialty Pharmacy and Retail Pharmacy and all major pharmacy chains. Just show your Member ID card any time you fill a prescription. Prescriptions filled at non-contracted pharmacies are covered only in certain situations.</p>
						<p><a href="https://www.medicareplanrx.com/jccf/mvp_pharmacy_23.html" className="find"> Find a pharmacy near you</a></p>

						<LearnMore showonly="kit" />
					</main>
					<SideBar location={location}/>

				</div>
			</div>
		</div>
	</Layout>
)

export default FindADoctor
